import logo from './violet.png';
import cheese from './pharmesan.png'
import Typist from 'react-typist';
import './App.css';


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="site-logo" alt="logo" />
          <Typist>

              <Typist.Delay ms={1000} />
              Violet pharmacy
              <Typist.Backspace count={15} delay={1000} />
              Zero dispensing fee
              <Typist.Backspace count={19} delay={1000} />
              5% drug markup
              <Typist.Backspace count={14} delay={1000} />
              Free delivery
              <Typist.Backspace count={13} delay={1000} />
              Ontario 2023...


          </Typist>
          <br/>

        <p>
            <img src={cheese} className="p-logo" alt="logo"/>
          node: <code>6LATpNsUWHvK9xTq5oSYYCZYu</code>
        </p>
        {/*
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>

        */}
      </header>
    </div>
  );
}

export default App;
